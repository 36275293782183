
import axios from 'axios'
import apikeyHeader from './apikey-headers';
import { HELPDESK, QUEUES, TICKETS, MANAGER, WORKFLOWS, BASE_API } from './urls'

const paramsFormatter = (params = {}) => {
  /**
    Function that formats query params before calling petition
    @param    {Object} params  Query params
    @return   {String}         Params formatted
  */
  Object.keys(params).forEach((k) => (!params[k].length && typeof params[k] !== 'number') && delete params[k])
  return Object.entries(Object.assign({}, params)).
    map(([key, value]) => {
      if(typeof value === 'object' && value.length){
        return value.map(val => `${key}=${val}`).join('&')
      }
      return `${key}=${value}`
    }).
    join('&')
}

class HelpDeskService {
  getInboxAvailable(){
    return axios.get(`${HELPDESK()}/get-inbox/`, { headers: apikeyHeader() });
  }
  getQueues() {
    const queryParams = {paginate: false, mine: true}
    return axios.get(`${QUEUES()}/`, { headers: apikeyHeader(), params: queryParams });
  }
  getQueuesBySegment() {
    const queryParams = {paginate: false, segment: true}
    return axios.get(`${QUEUES()}/`, { headers: apikeyHeader(), params: queryParams });
  }
  getQueuesByDelegate() {
    const queryParams = {paginate: false, delegate: true}
    return axios.get(`${QUEUES()}/`, { headers: apikeyHeader(), params: queryParams });
  }
  getTicket(id){
    return axios.get(`${TICKETS()}/${id}/`, { headers: apikeyHeader() });
  }
  getTickets(page, search = '', order = ''){
    return axios.get(`${TICKETS()}/`, { headers: apikeyHeader(), params: { page: page, order, search : search } });
  }
  getMyTickets(page, search = '',order = ''){
    return axios.get(`${TICKETS()}/me/`, { headers: apikeyHeader(), params: { page: page, order, search : search } });
  }

  addTicket(form){
    return axios.post(`${TICKETS()}/`, form, { headers: apikeyHeader() });
  }

  editTicket(id, data){
    return axios.patch(`${TICKETS()}/${id}/`, data, { headers: apikeyHeader() });
  }

  addFollowup(ticketID, formData){
    return axios.post(`${TICKETS()}/${ticketID}/followups/`, formData, { headers: apikeyHeader() });
  }

  addAttachment(remoteID, formData){
    return axios.post(`${HELPDESK()}/followups/${remoteID}/massive-attachments/`, formData, { headers: apikeyHeader() });
  }
  addAttachmentAnswer(remoteID, formData ){
    return axios.post(`${HELPDESK()}/answer/${remoteID}/massive-attachments/`, formData, { headers: apikeyHeader() });
  }
  downloadsTickets(){
    const url = `${TICKETS()}/download/`
    let options = {
      method: 'GET',
      headers: apikeyHeader(),
      url,
      responseType: 'arraybuffer'
    }
    return axios(options)
  }
  detailStepWorkFlow(ticketID = null){
    const url = `${WORKFLOWS()}/ticket/${ticketID}`
    const config = {
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)

  }
  getResponseTicketWithWorkflow(ticketID = null){
    const url = `${WORKFLOWS()}/ticket/${ticketID}/response`
    const config = {
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  getTicketsbyManager(params = {}){
    const url = `${MANAGER()}/tickets`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader(),
      params: params,
      paramsSerializer: function paramsSerializer(params) {
        return paramsFormatter(params)
      }
    }
    return axios(config)
  }
  deleteTicket(id = null){
    const url = `${TICKETS()}/${id}/`
    const config = {
      method: 'DELETE',
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  getManagerQueues(){
    const url = `${MANAGER()}/queues`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  sendReminder(payload = {}){
    const url = `${MANAGER()}/send-reminder`
    const config = {
      method: 'POST',
      url: url,
      headers: apikeyHeader(),
      data: payload
    }
    return axios(config)
  }
  getManagerReport(params = {}){
    const url = `${MANAGER()}/report`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader(),
      params: params,
      paramsSerializer: function paramsSerializer(params) {
        return paramsFormatter(params)
      }
    }
    return axios(config)
  }

  taskStatusReq(taskId) {
    const url = `${BASE_API()}/helpdesk/celery-api/status/${taskId}`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader(),
    }
  
    return axios(config)
  }
  
  taskDownloadReq(taskId) {
    const url = `${BASE_API()}/helpdesk/celery-api/download/${taskId}`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader(),
      responseType: 'blob'
    }
  
    return axios(config)
  }

  getTicketManagerDetail(id = null){
    const url = `${MANAGER()}/ticket/${id}/detail`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  reassignTicket(payload = {}){
    const url = `${MANAGER()}/ticket/assign`
    const config = {
      method: 'POST',
      url: url,
      data: payload,
      headers: apikeyHeader()
    }
    return axios(config)
  }
}

export default new HelpDeskService();
