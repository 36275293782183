import axios from 'axios'
import apikeyHeader from './apikey-headers'
import { NEXT, POSTS } from './urls'

class FeedService {
  searchItems(params = {}){
    const url = `${POSTS()}/search/`
    const config = {
      url: url,
      headers: apikeyHeader(),
      params: params
    }
    return axios(config)
  }
  getFeedPosts(params = {}){
    const url = `${POSTS()}/`
    const config = {
      url: url,
      headers: apikeyHeader(),
      params: params
    }
    return axios(config)
  }
  getFeaturedPosts(){
    const url = `${POSTS()}/feed/`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  likePost(payload = {}){
    const url = `${POSTS()}/${payload.postId}/reaccionar/`
    const config = {
      method: 'POST',
      url: url,
      headers: apikeyHeader(),
      data: {
        reaccion: payload.reactionId
      }
    }
    return axios(config)
  }
  unlikePost(id = null){
    const url = `${POSTS()}/${id}/reaccionar/`
    const config = {
      method: 'DELETE',
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  getPostDetail(id = null){
    const url = `${POSTS()}/${id}/`
    const config = {
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  getPostLikes(id = null){
    const url = `${POSTS()}/${id}/1/reaccionaron/`
    const config = {
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  getPostComments(payload = {}){
    const url = `${POSTS()}/${payload.id}/comentarios/`
    const config = {
      method: 'GET',
      url: url,
      headers: apikeyHeader(),
      params: {
        page: payload.page || 1
      }
    }
    return axios(config)
  }
  postAComment(payload = {}){
    const url = `${POSTS()}/`
    const config = {
      method: 'POST',
      url: url,
      headers: apikeyHeader(),
      data: payload
    }
    return axios(config)
  }
  deleteComment(id = null){
    const url = `${POSTS()}/${id}/eliminar/`
    const config = {
      method: 'DELETE',
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  reportComment(id = null){
    const payload = {reportado: true}
    const url = `${POSTS()}/${id}/edit/`
    const config = {
      method: 'PATCH',
      url: url,
      headers: apikeyHeader(),
      data: payload
    }
    return axios(config)
  }
  sendVotePulseSurvey(payload = {}){
    const url = `${NEXT()}/polls/answers/${payload.id}/`
    const config = {
      method: 'PUT',
      url: url,
      headers: apikeyHeader(),
      data: payload.data
    }
    return axios(config)
  }
}

export default new FeedService();
