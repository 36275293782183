import Vue from 'vue'
import moment from 'moment'

import { mdiImageOutline, mdiFileDocument, mdiVideoOutline } from '@quasar/extras/mdi-v5'

Vue.filter('lastPathName', function(value:any) {
  if(!value) return ''
  return value.substring(value.lastIndexOf('/') + 1, value.lastIndexOf('?Expires'))
});

Vue.filter("evaluationModality", function(value:string = ''){
  switch(value) {
    case 'score':
      return 'pts'
    case 'percentage':
      return '%'
    default:
      return ''
  }
})

Vue.filter("evaluationStatusColor", function(value: string = ''){
  switch (value) {
    case 'Pendiente':
    case 'Pendiente de carga':
      return 'bg-grey'
    case 'En proceso':
      return 'bg-warning'
    case 'Finalizada':
    case 'Finalizado':
    case 'Objetivos cargados':
      return 'bg-green-6'
    case 'Finalizado/Con comentarios':
      return 'bg-red'
    case 'Finalizado/Aceptado':
    default:
      return 'bg-primary'
  }
}),

Vue.filter('timeNotation', function(value: string = ''){
  if(value){
    return moment(value, 'h:mm:ss').format('HH:mm')
  }
  return '-'
})

Vue.filter("CDRstatusColor", function(value: number = 0){
  switch (value) {
    case 1:
    case 6:
      return 'grey'
    case 2: 
      return 'warning'
    case 3:
    case 4:
      return 'red'
    case 5:
      return 'green-6'
    default:
      return 'primary'
  }
})

Vue.filter("CDRstatusName", function(value: number = 0){
  switch (value) {
    case 1:
    case 6:
      return 'Abierto'
    case 2: 
      return 'En progreso'
    case 3:
      return 'Cancelado'
    case 4:
      return 'Rechazado'
    case 5:
      return 'Aceptado'
    default:
      return ''
  }
})

Vue.filter("prettyDateFull", function(value: string = ''){
  if(value){
    return moment(value).format('DD MMMM YYYY')
  }
})

Vue.filter("formatDate", function(date: string = '', format: string = 'DD MMMM YYYY'){
  return date ? moment(date).format(format) : 'Fecha no disponible'
})

Vue.filter("kb", function(value: number = 0){
  return Math.floor(value / 1024)
})

Vue.filter('LiquidacionesName', function(value: string = ''){
  if(value.includes('Liquidacion')){
    const name = value.split(' ')
    const writtenDate = name[1] ? moment(name[1].replace('/', '-'), 'MM-YYYY').format('MMMM YYYY') : ''
    return `Liquidación ${writtenDate}`
  }
  return value
})

Vue.filter('fileIcon', function(value: string){
  const images = ['png', 'jpg', 'jpeg', 'gif']
  const video = ['mp4', 'webp', 'webm', 'mov', 'avi']

  if(images.some(format => value.includes(format))){
    return mdiImageOutline
  }else if(video.some(format => value.includes(format))){
    return mdiVideoOutline
  }else{
    return mdiFileDocument
  }
})

Vue.filter('setPercentage', function(value: number): string{
  return `${value * 100}%`
})

Vue.filter('truncateText', function(text: string = '', maxLength: number = 60): string{
  return (text || '').length > maxLength ? `${text.substring(0, maxLength)}...` : text
})

Vue.filter('roundNumber', function(number: number = 0): number{
  return Math.round(number)
})

Vue.filter('capitalizeFirstLetter', function (text = ''){
  return text.charAt(0).toUpperCase() + text.slice(1)
})
