export interface Todo {
  id: number;
  content: string;
}

export interface Meta {
  totalCount: number;
}

export interface Document {
  url: string;
  date: Date;
  name: string;
}

export interface Request {
  state: string;
  resume: string;
  type: RequestType;
}

export class Mark {
  direction: string
  date: Date
  title: string
  id: number
  constructor(map:any) {
    this.direction = map.direction
    this.date = map.TS
    this.title = map.direction === 'E' ? 'Entrada' : map.direction === 'X' ? 'Salida' : 'Otro'
    this.id = map.id
  }
}

export enum RequestType {
  Holidays = "Holidays",
  Administratives = "Administratives",
  Permissions = "Permissions",
  GiveNotice = "GiveNotice",
  ExtraHours = "ExtraHours",
  Replacements = "Replacements",
  HelpDesk = "HelpDesk",
  Task = "Task",
  Mark = "Mark"
}

export interface RequestAction {
  name: string;
  type: RequestType;
  icon: string;
  to: string;
}

export enum TicketStatus {
  OPEN_STATUS = 1,
  PROGRESS_STATUS = 2,
  CANCELED_STATUS = 3,
  REJECTED_STATUS = 4,
  RESOLVED_STATUS = 5,
  OPEN_WORKFLOW = 6
}
