<template>
  <div id="q-app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created(){
    localStorage.removeItem('pendingTerms')
    localStorage.removeItem('termsSkipped')
    if(this.$q?.platform?.is?.desktop && !this.$q?.platform?.is?.mac){
      import('./css/components/scrollbar.scss')
    }
  }
}
</script>

<style lang="scss">
@import "~@/css/app.scss";
</style>