import axios from 'axios'
import authHeader from './auth-header'
import apikeyHeader from './apikey-headers';
import { NEXT, V1 } from './urls';

class UserService {
  getProfile() {
    return axios.get(`${V1()}/myself/`, { withCredentials: true, headers: authHeader() })
    .then(response => {
      if (response.data.myId) {
        localStorage.setItem('profile', JSON.stringify(response.data));
      }
      return response.data;
    });
  }
  getConfigurations() {
    return axios.get(`${V1()}/configuraciones/`, { withCredentials: true, headers: authHeader() })
    .then(response => {
      if (response.data) {
        localStorage.setItem('configurations', JSON.stringify(response.data));
      }
      return response.data;
    });
  }
  getBrandConfigurations() {
    return axios.get(`${NEXT()}/configuration/`, { headers: apikeyHeader() })
  }
  getStats(){
    return axios.get(`${V1()}/vacations/stats`, { withCredentials: true, headers: authHeader() });
  }

  getContacts(payload = {}) {
    return axios.get(`${V1()}/contact-new`,
      { 
        withCredentials: true,
        headers: authHeader(),
        params: payload
      }
    );
  }

  setUserSession(payload = {}){
    const url = `${NEXT()}/estadisticas/sesion/`
    const config = {
      method: 'POST',
      url,
      headers: apikeyHeader(),
      data: payload
    }
    return axios(config)
  }
}

export default new UserService();
