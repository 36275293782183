import TermsService from '../services/terms.service'
import { Module } from 'vuex'
import { TermsAndConditions } from '@/models/terms'

export const termsAndConditions: Module<any, any> = {
  namespaced: true,
  state: {
    terms: ''
  },
  mutations: {
    setTerms(state, file){
      state.terms = file
    }
  },
  actions: {
    async getPendingTerms({commit, dispatch}){
      try {
        const { data } = await TermsService.getPendingTerms()
        commit('setTerms', data)
        if(data.vencido){
          localStorage.removeItem('termsSkipped')
        }
        localStorage.setItem('pendingTerms', 'true')
        return data
      } catch ({ response: {data} }) {
        localStorage.removeItem('pendingTerms')
        if(data?.detail === 'No hay Términos y Condiciones pendiente.'){
          dispatch('getTermsList')
          return true
        }
        return false
      }
    },
    async signTermsAndConditions({state}, data){
      const payload = {
        id: state.terms.id,
        data
      }
      try {
        await TermsService.signTermsAndConditions(payload)
        return true
      } catch (error) {
        return false
      }
    },
    async getTermsList({commit}){
      try {
        const { data } = await TermsService.getTermsList()
        const active = data.filter(term => term.activo)[0]
        commit('setTerms', active)
      } catch ({ response: {data} }) {
        return false
      }
    },
  },
  getters: {
    getTerms(state): TermsAndConditions{
      return state.terms || {}
    },
    getTermsFile(state): string{
      return state.terms?.archivo || ''
    },
  }
}