import * as firebase from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyAVuYxca9LbL9sfFgUTazPa5qDCpVXqZEw",
  authDomain: "talana-notificaciones.firebaseapp.com",
  databaseURL: "https://talana-notificaciones.firebaseio.com",
  projectId: "talana-notificaciones",
  storageBucket: "talana-notificaciones.appspot.com",
  messagingSenderId: "387197034009",
  appId: "1:387197034009:web:33576efbb2a68892081488",
  measurementId: "G-MYG8WMD6VS"
}

let firebaseApp = firebase.initializeApp(firebaseConfig)

const logFirebaseEvent = (name = '', params = {}) => {
  const profile = JSON.parse(localStorage.profile)
  const userData = {
    user_id: profile?.myId,
    company_id: profile?.empresa,
    os: 'MiTalana',
    ...params
  }
  firebase.analytics().logEvent(name, userData)
}

export { firebaseApp, logFirebaseEvent }