import WorkshiftsService from '../services/workshifts.service'
import { DjangoPagination } from '@/models/responses'
import { Module } from 'vuex'
import notify from '../boot/notify-defaults'
import { WorkshiftType } from '@/models/workshifts'
import { ConfigurationBusinessType } from '@/models/core-models'

const defaultState = {
  items: [],
  count: 0,
  page: 1
}

export const workshifts: Module<any, any> = {
  namespaced: true,
  state: {
    types: [
      {
        name: 'En espera',
        singular: 'En espera',
        value: 'en-espera',
        status: 'requested',
        color: 'talana-orange',
      },
      {
        name: 'Aprobadas',
        singular: 'Aprobada',
        value: 'aprobadas',
        status: 'accepted',
        color: 'hulk-green'
      },
      {
        name: 'Rechazadas',
        singular: 'Rechazada',
        value: 'rechazadas',
        status: 'rejected',
        color: 'ironman-red'
      },
    ],
    accepted: {},
    rejected: {},
    requested: {},
  },
  mutations: {
    setItems(state, payload: {data: DjangoPagination, status: string}){
      state[payload.status].items = [...state[payload.status].items, ...payload.data.results]
      state[payload.status].count = payload.data.count
      state[payload.status].page = !!payload.data.next ? state[payload.status].page + 1 : 0
    },
    resetStatus(state, status: string){
      state[status] = {...defaultState}
    },
    setError(state, status: string){
      state[status].page = 0
    }
  },
  actions: {
    getWorkshiftsList({ commit, state, getters }, status: string = ''){
      const params = {
        status,
        page: state[status]?.page || 1
      }
      if(params.page === 1 && state[status]?.items.length){
        commit('resetStatus', status)
      }
      return WorkshiftsService.getWorkshiftsSummaryList(params)
        .then(({data}) => {
          commit('setItems', { data, status})
        })
        .catch(({response: { data } = { data: { status: [] }}}) => {
          commit('setError', status)
          notify.sendNotification({
            type: 'error',
            message: data?.status?.length ? data?.status[0] : `Error al traer jornadas ${getters.findType(status)?.name.toLowerCase()}`
          })
        })
    },
    getAllStatus({dispatch, commit, state}){
      state.types.forEach(type => {
        commit('resetStatus', type.status)
        dispatch('getWorkshiftsList', type.status)
      })
    },
    getWorkshiftDetail(_, params = {}){
      return WorkshiftsService.getWorkshiftsSummaryList(params)
        .then(({data}) => data.results[0] || {})
        .catch(({response: { data } = { data: { status: [] }}}) => {
          notify.sendNotification({
            type: 'error',
            message: data?.status?.length ? data?.status[0] : 'Error al traer detalle de jornada'
          })
          return false
        })
    },
    modifyWorkshift({dispatch}, payload){
      return WorkshiftsService.modifyWorkshift(payload)
        .then(() => {
          notify.sendNotification({
            type: 'success',
            message: 'Se ha enviado la respuesta exitosamente'
          })
          dispatch('getAllStatus')
          return true
        })
        .catch(({response: { data } = { data: {} as { detail: string } }}) => {
          notify.sendNotification({
            type: 'error',
            message: data?.detail || 'Error al enviar respuesta'
          })
          return false
        })
    }
  },
  getters: {
    getItemsByType: (state) => (type: string) => {
      return state[type] || defaultState
    },
    getTypes(state): WorkshiftType[]{
      return state.types
    },
    findType: (_, getters) => (status: string) => {
      return getters.getTypes.find(type => Object.keys(type).find(key => type[key] === status))
    },
    showWorkshiftTab(state, getters, rootState, rootGetters): boolean{
      return rootGetters['configurations/getSettingType'](ConfigurationBusinessType.ShowWorkshiftCorrections)?.dataValue() || false
    }
  }
}