import Vue from 'vue'
import './plugins/quasar'
import './boot/filters'
import './boot/firebase.js'
import i18n from './boot/i18n'
import './boot/moment'
import './boot/notify-defaults'
import './boot/utils.js'
import App from './App.vue'
import router from './router'
import store from './store'
import './sw-removal'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
