import ClimateService from "../services/climate.service.js";

const state = {}
const mutations = {}
const actions = {
  async getCurrentClimatePollURL(_, id) {
    const {data} = await ClimateService.getCurrentClimatePollURL(id)

    return data
  },
  // Objectives
  async getPerformanceEvaluations(_) {
    const {data} = await ClimateService.getPerformanceEvaluations()

    return data
  },
  async getObjectivesEvaluationsUsers(_, id = null){
    const { data } = await ClimateService.getObjectivesEvaluationUsers(id)
    if(data.length){
      const evaluation = {
        evaluation: data[0].evaluation.name,
        people: data
      }
      return evaluation
    }
    return false
  },
  async getEvaluatedObjectiveReview(_, id = null){
    const { data } = await ClimateService.getEvaluatedObjectiveReview(id)
    return data
  },
  async getEvaluatedObjectiveFeedbackReview(_, id = null){
    const { data } = await ClimateService.getEvaluatedObjectiveFeedbackReview(id)
    return data
  },
  async postEvaluatedObjectiveFeedbackReview(_, payload = {}){
    const { data } = await ClimateService.postEvaluatedObjectiveFeedbackReview(payload)
    return data
  },
  // Skills
  async getCompetenciesEvaluations(_) {
    const {data} = await ClimateService.getCompetenciesEvaluations()
    return data
  },
  async getCompetenciesEvaluationsUsers(_, id = null){
    const { data } = await ClimateService.getCompetenciesEvaluationUsers(id)
    if(data.length){
      const evaluation = {
        evaluation: data[0].evaluation.name,
        people: data
      }
      return evaluation
    }
    return false
  },
  async getEvaluatedCompetenciesReview(_, id = null){
    const { data } = await ClimateService.getEvaluatedCompetenciesReview(id)
    return data
  },
  async getEvaluatedCompetenciesFeedbackReview(_, id = null){
    const { data } = await ClimateService.getEvaluatedCompetenciesFeedbackReview(id)
    return data
  },
  async postEvaluatedCompetenciesFeedbackReview(_, payload = {}){
    const { data } = await ClimateService.postEvaluatedCompetenciesFeedbackReview(payload)
    return data
  },
}

export default {
  state,
  mutations,
  actions
}