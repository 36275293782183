import UserService from "../services/user.service.js";
import PayrollService from "../services/payroll.service.js";
import { ConfigurationBusiness, EnabledServices } from "../models/core-models.ts";

const configurationsObject = JSON.parse(localStorage.getItem('configurations'));
const enabledServicesObject = JSON.parse(localStorage.getItem('services'));

const initialState = configurationsObject
  ? { configurations: configurationsObject.map(a => new ConfigurationBusiness(a)), enabledServices: (enabledServicesObject) ? enabledServicesObject : null }
  : { configurations: null, enabledServices: (enabledServicesObject) ? enabledServicesObject : null};

export const configurations = {
  namespaced: true,
  state: initialState,
  actions: {
    get({ commit }) {
      return UserService.getConfigurations().then(
        configurations => {
          commit('configurationsSuccess', configurations.map(a => new ConfigurationBusiness(a)));
          return Promise.resolve(configurations.map(a => new ConfigurationBusiness(a)));
        },
        error => {
          commit('configurationsFailure');
          return Promise.reject(error);
        }
      );
    },
    services({ commit }) {
      return PayrollService.getEnabledServices().then(
        services => {
          commit('servicesSuccess', new EnabledServices(services));
          return Promise.resolve(new EnabledServices(services));
        },
        error => {
          commit('servicesFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    configurationsSuccess(state, configurations) {
      state.configurations = configurations;
    },
    configurationsFailure(state) {
      state.configurations = null;
    },
    servicesSuccess(state, services) {
      state.enabledServices = services;
    },
    servicesFailure(state) {
      state.enabledServices = null;
    }
  },
  getters: {
    getSettingType: (state) => (type) => {
      return state.configurations?.find(configuration => configuration.type === type);
    },
    getSettings: state => {
      return state.configurations
    },
    getEnabledServices: state => {
      return state.enabledServices
    },
    disabledConfig: (_, getters) => (type) => {
      const findConfig = getters.getSettingType(type)
      return !findConfig || findConfig && !findConfig.dataValue()
    },
    hasCom: (_, getters) => {
      return !!getters.getEnabledServices?.comunicaciones
    }
  }
};