import axios from 'axios'
import apikeyHeader from './apikey-headers'
import { BENEFITS } from './urls'

class BenefitsService {
  getBenefits(params = {}){
    const url = `${BENEFITS()}`
    const config = {
      url: url,
      headers: apikeyHeader(),
      params: params
    }
    return axios(config)
  }
  toggleFavBenefit(payload = {}){
    const url = `${BENEFITS()}/favoritos/toggle`
    const config = {
      url,
      headers: apikeyHeader(),
      method: 'POST',
      data: payload
    }
    return axios(config)
  }
  getFavBenefits(){
    const url = `${BENEFITS()}/favorito/`
    const config = {
      url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  agreeTermsAndConditions(data = {}){
    const url = `${BENEFITS()}/aceptar-tyc`
    const config = {
      url,
      data,
      method: 'POST',
      headers: apikeyHeader()
    }
    return axios(config)
  }
}

export default new BenefitsService();
