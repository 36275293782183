import { Module } from 'vuex'
import EventsServices from '../services/events.service'

export const events: Module<any, any> = {
  namespaced: true,
  actions: {
    async getEvents(): Promise<any>{
      const { data } = await EventsServices.getEvents()
      return data
    },
    async setAttendance(_, payload = {}): Promise<boolean>{
      await EventsServices.setAttendance(payload)
      return true
    }
  }
}