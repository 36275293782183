import UserService from '../services/user.service'
import WorkshiftsService from '@/services/workshifts.service';
import { Module } from 'vuex'
import notify from '@/boot/notify-defaults'
import { requestStats } from '@/models/requests'

const profileObject = JSON.parse(localStorage.getItem('profile') as string);
const initialState = profileObject
  ? { status: true, profile:profileObject, shifts: {}, brand: {}, stats: null }
  : { status: false, profile: null, shifts: {}, brand: {}, stats: null };

export const profile: Module<any, any> = {
  namespaced: true,
  state: initialState,
  actions: {
    get({ commit }) {
      return UserService.getProfile().then(
        profile => {
          commit('profileSuccess', profile);
          return Promise.resolve(profile);
        },
        error => {
          commit('profileFailure');
          return Promise.reject(error);
        }
      );
    },
    getShift({commit}){
      WorkshiftsService.getShifts().then(
        response => {
          if(response.data){
            commit('setShifts', response.data)
          }
        },
        error => {
          commit('setShifts', {})
        }
      )
    },
    async getBrandConfigurations({commit}){
      const { data } = await UserService.getBrandConfigurations()
      if(data){
        commit('setBrandConfig', data)
        return data
      }
    },
    async getUserStats({commit}){
      UserService.getStats().then(
        ({data}) => {
          commit('setStats', new requestStats(data) || {})
        },
        error => {
          notify.sendNotification({
            message: 'Error al traer estadísticas, intente más tarde',
            type: 'error'
          })
        }
      );
    }
  },
  mutations: {
    profileSuccess(state: any, profile) {
      state.status = true;
      state.profile = profile;
    },
    profileFailure(state: any) {
      state.status = false;
      state.profile = null;
    },
    setShifts(state: any, shifts = {}){
      state.shifts = shifts
    },
    setBrandConfig(state, brand){
      state.brand = {
        logo: brand.logo || false,
        primary: brand.primario
      }
    },
    setStats(state, stats){
      state.stats = stats
    }
  },
  getters: {
    getProfile: (state) => {
      return state?.profile || {}
    },
    isBoss: (state) => {
      return state.profile.is_boss;
    },
    getPermission: (state) => (permissionName:string) => {
      if(state.profile != null){
        const permissions = state.profile.permisos as [{ nombre: string }]
        return permissions.find(permission => permission.nombre == permissionName);
      }else{
        return null
      }
    },
    workShifts: (state) => {
      return state.shifts || null
    },
    getBrand: (state) => {
      return state.brand
    },
    chileanCompany: (state): boolean => {
      return state.profile?.empresaDetails?.pais === 'CL'
    },
    companyCountry: (state): boolean => {
      return state.profile?.empresaDetails?.pais || 'CL'
    },
    getHolidayStats: (state) => {
      return state.stats?.estadisticasVacaciones || null
    },
    getStats(state){
      return state.stats || null
    }
  }
};