import Vue from 'vue'
import { BASE_API_SASS } from '@/services/config'

Vue.mixin({
  methods: {
    openAttachment(url) {
      window.open(url, "_blank");
    },
    groupBy(array, fn) {
      let result = {};
      array.forEach(item => {
        let key = fn(item);
    
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(item);
      });
      return result;
    },
    getQueryStringValue(key) {
      return decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            "^(?:.*[&\\?]" +
              encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
              "(?:\\=([^&]*))?)?.*$",
            "i"
          ),
          "$1"
        )
      )
    },
    // Este método sirve para poder ver imágenes en qa y en local
    userImage(image = ''){
      let url = new URL(image)
      if(url.host === 'web:9000'){
        return BASE_API_SASS() + url.pathname
      }
      return image
    },
    // CDR Manager Actions
    noActionsAllowed(status = 0){
      if(status === 3 || status === 4 || status === 5){
        return true
      }
      return false
    },
    formatTwoDigitsNumber(value = 0){
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
      return formatter.format(value)
    },
    objectIsEmpty(obj = {}){
      return !Object.keys(obj).length
    },
    toBase64(file){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    }
  }
})
