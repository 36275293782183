import FeedService from "../services/feed.service.js"
import { GenericPost } from "../models/next"

const state = {
  normalPosts: [],
  page: 1,
  loadingLike: false,
  loadingPosts: false,
  featuredPosts: [],
  featuredBenefits: []
}

const mutations = {
  setLoadingLikeButton(state, value){
    state.loadingLike = value
  },
  setLoadingPosts(state, value){
    state.loadingPosts = value
  },
  setPostLike(state, payload = { type: '', id: null, reaccion: null, total_reacciones: null}){
    const type: string = payload.type === 'featured' ? 'featuredPosts' : 'normalPosts'
    const index: number = state[type].findIndex(post => post.id === payload.id)
    if(index >= 0){
      state[type][index].mi_reaccion = payload.reaccion
      state[type][index].total_reacciones = payload.total_reacciones ? payload.total_reacciones : state[type][index].total_reacciones - 1
    }
  },
  setPostTotalComments(state, payload = { type: '', id: null, action: 'add' }){
    const number: number = payload.action === 'add' ? 1 : -1
    const type: string = payload.type === 'featured' ? 'featuredPosts' : 'normalPosts'
    const index: number = state[type].findIndex(post => post.id === payload.id)
    if(index >= 0){
      state[type][index].total_comentarios = state[type][index].total_comentarios + number
    }
  },
  setNormalPosts(state, data){
    state.normalPosts = [].concat(state.normalPosts, data.posts)
    state.page = data.page
  },
  setFeaturedPosts(state, data){
    state.featuredPosts = data
  },
  setVote(state, payload = {type: '', id: null, poll: {}}){
    const type: string = payload.type === 'featured' ? 'featuredPosts' : 'normalPosts'
    const index: number = state[type].findIndex(post => post.id === payload.id)
    if(index >= 0){
      state[type][index].encuesta = payload.poll
    }
  },
  setFeaturedBenefits(state, benefits = []){
    state.featuredBenefits = benefits
  },
  setEventAttendance(state, payload = {type: '', id: null, event: { asistire: false, asistentes: 0}}){
    const index: number = state[payload.type].findIndex(post => post.id === payload.id)
    if(index >= 0){
      state[payload.type][index].evento.asistire = payload.event.asistire
      state[payload.type][index].evento.asistentes = payload.event.asistentes
    }
  },
  setEventInterest(state, payload = {type: '', id: null, event: { meInteresa: false, interesados: 0}}){
    const index: number = state[payload.type].findIndex(post => post.id === payload.id)
    if(index >= 0){
      state[payload.type][index].evento.me_interesa = payload.event.meInteresa
      state[payload.type][index].evento.interesados = payload.event.interesados
    }
  },
}

const actions = {
  async getFeedPosts({state, commit, dispatch}){
    commit('setLoadingPosts', true)
    const { data } = await FeedService.getFeedPosts({page: state.page || 1})
    if(data){
      const posts: GenericPost[] = data.results
      const payload = {
        posts: posts,
        page: data.next ? state.page+1 : false
      }
      commit('setNormalPosts', payload)
      commit('setLoadingPosts', false)
      if(state.page && state.normalPosts.length < 20 && state.loadingPosts){
        dispatch('getFeedPosts')
      }
    }
  },
  async getFeaturedPosts({commit}){
    commit('setLoadingPosts', true)
    const { data } = await FeedService.getFeaturedPosts()
    if(data){
      const postTyped: GenericPost[] = data.posts
      const benefits: any[] = data.beneficios || []
      commit('setFeaturedBenefits', benefits)
      commit('setFeaturedPosts', postTyped)
      commit('setLoadingPosts', false)
    }
  },
  async likePost({commit}, info = { id: null, type: 'normal'}){
    commit('setLoadingLikeButton', true)
    const payload = {
      postId: info.id,
      reactionId: 1
    }
    const { data } = await FeedService.likePost(payload)
    if(data){
      const payload = {
        type: info.type,
        id: info.id,
        reaccion: data.reaccion,
        total_reacciones: data.total_reacciones
      }
      if(info.type !== 'groups'){
        commit('setPostLike', payload)
      }
      commit('setLoadingLikeButton', false)
      return payload
    }
  },
  async unlikePost({commit}, info = { id: null, type: 'normal'}){
    commit('setLoadingLikeButton', true)
    await FeedService.unlikePost(info.id)
    const payload = {
      type: info.type,
      id: info.id,
      reaccion: null,
      total_reacciones: null
    }
    if(info.type !== 'groups'){
      commit('setPostLike', payload)
    }
    commit('setLoadingLikeButton', false)
  },
  async getPostDetail(_, id = null){
    const { data } = await FeedService.getPostDetail(id)
    return data
  },
  async getPostLikes(_, id = null){
    const { data } = await FeedService.getPostLikes(id)
    return data
  },
  async getPostComments(_, payload = {}){
    const { data } = await FeedService.getPostComments(payload)
    return data
  },
  async postAComment({commit}, payload = { comment: { thread: null }, type: ''}){
    const { data } = await FeedService.postAComment(payload.comment)
    if(data){
      commit('setPostTotalComments', {type: payload.type, id: payload.comment.thread, action: 'add'})
      return data
    }
  },
  async getPostsByCategory({commit}, payload = {}){
    commit('setLoadingPosts', true)
    const { data } = await FeedService.getFeedPosts(payload)
    if(data){
      commit('setLoadingPosts', false)
      return data
    }
  },
  async searchItems(_, params = {}){
    const { data } = await FeedService.searchItems(params)
    return data
  },
  async deleteMyComment({commit}, payload = {commentId: null, type: '', postId: null}){
    const { data } = await FeedService.deleteComment(payload.commentId)
    if(payload.type !== 'groups'){
      commit('setPostTotalComments', {type: payload.type, id: payload.postId, action: 'delete'})
    }
    return data
  },
  async reportComment(_, id = null){
    await FeedService.reportComment(id)
    return true
  },
  async sendPollVote(_, payload = {}){
    const { data } = await FeedService.sendVotePulseSurvey(payload)
    return data
  },
  setPollVote({getters, commit}, payload = {id: null, poll: {}}){
    const type = getters.postType(payload.id)
    commit('setVote', {type, ...payload})
  },
  updateEvent({getters, commit}, payload = {id: null, event: {}}){
    const type = getters.postTypeKey(payload.id)
    if(payload.event.hasOwnProperty('asistentes')){
      commit('setEventAttendance', {type, ...payload})
    }else if(payload.event.hasOwnProperty('interesados')){
      commit('setEventInterest', {type, ...payload})
    }
  },
}

const getters = {
  postType: (state) => (id: string) => {
    const idNumber = Number(id)
    const found = state.featuredPosts.find(post => post.id === idNumber)
    return found ? 'featured' : 'normal'
  },
  getLoadingLike(state): boolean{
    return state.loadingLike
  },
  postTypeKey: (_, getters) => (id: number) => {
    const type = getters.postType(id)
    return type === 'featured' ? 'featuredPosts' : 'normalPosts'
  },
  hasPosts(state): boolean{
    return !!state.normalPosts?.length || !!state.featuredPosts?.length || state.loadingPosts
  } 
}

export default {
  state,
  mutations,
  actions,
  getters
}