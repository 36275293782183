import { ConfigurationBusinessType } from '../models/core-models'
import { configurations } from '../store/configurations.store'

export enum RequestState {
  Pending = "P",
  Accepted = "A",
  Rejected = "R",
  Canceled = "C"
}

export enum RequestType {
  Holidays = "holidays",
  Administratives = "administratives",
  Permissions = "permissions",
  GiveNotice = "givenotice",
  ExtraHours = "extrahours",
  Replacements = "replacements"
  //HelpDesk = "helpdesk",
  //Task = "tasks"
}

export class HolidaysItem{
    numberDays: number;
    halfDay: boolean;
    typeVacation: string;
    time: string;
    initDay: string;
    finishDay: string;
    returnDay: string;

    constructor(map:any) {
        this.numberDays = map.numeroDias;
        this.halfDay = map.mediosDias;
        this.typeVacation = map.tipoVacaciones;
        this.time = map.jornada;
        this.initDay = map.vacacionesDesde;
        this.finishDay = map.vacacionesHasta;
        this.returnDay = map.vacacionesRetorno;
    }

}

export class AdministrativesItem extends HolidaysItem{

    administrative_type: string;
    comments: string;

    constructor(map:any) {
        super(map)
        this.administrative_type = map.administrative_type;
        this.comments = map.comments;
        this.initDay = map.desde;
        this.finishDay = map.hasta;
        this.returnDay = map.retorno;
    }

}

export class PermissionItem extends HolidaysItem{

    type: string;
    motive: string;
    comments: string;
    initHour: string;
    finishHour: string;
    numberHours: number;
    numberMinutes: string;

    constructor(map:any) {
        super(map)
        this.type = map.tipoAusencia;
        this.motive = map.motivo;
        this.comments = map.observaciones;
        this.initHour = map.horaDesde;
        this.finishHour = map.horaHasta;
        this.numberHours = map.numeroHoras;
        this.numberMinutes = map.numeroMinutos;
        this.initDay = map.fechaDesde;
        this.finishDay = map.fechaHasta;
        this.returnDay = map.fechaRetorno;
    }

}

export class ExtraHoursItem{
    hours: number;
    date: Date;
    reason: string;
    observations: string;

    constructor(map:any) {
        this.hours = map.hours;
        this.date = map.date;
        this.reason = map.reason;
        this.observations = map.observations;
    }
}


export class RequestGeneric {
  remoteID: number;
  state: RequestState;
  type = RequestType.Holidays;
  motive: string;
  dateCreated: string;
  requestObject: any;
  user: number;
  workerName: string;
  workerLastName: string;
  workerAvatar: string;

  constructor(map: any) {
    this.remoteID = map.id;

    if (typeof map.aprobada === "boolean"){
        this.state = (map.aprobada) ? RequestState.Accepted : RequestState.Pending
    }else{
        this.state = map.aprobada;
    }

    if(map.type == 'extrahours'){
        if (map.status === 'approved' || map.status === 'accepted') this.state = RequestState.Accepted
        if (map.status === 'canceled') this.state = RequestState.Canceled
        if (map.status === 'rejected') this.state = RequestState.Rejected
        if (map.status === 'pending') this.state = RequestState.Pending
    }

    if(map.type == null){
        this.type = RequestType.Permissions;
    }else{
        this.type = map.type;
    }

    this.workerName = map.type != 'extrahours' ? map.detallesTrabajador.nombre : map.person.nombre 
    this.workerLastName = map.type != 'extrahours' ? map.detallesTrabajador.apellidoPaterno : map.person.apellidoPaterno;
    this.workerAvatar = map.type != 'extrahours' ? map.detallesTrabajador.detalles[0].foto : map.person.personadetalle_set[0].foto;
    this.user = map.type != 'extrahours' ? map.empleado : map.person.id
    this.motive = "Sin definir";

    
    switch (this.type) {
        case RequestType.Holidays: {
            if(map.mediosDias){
                this.motive = "Medio día de vacaciones"
            }else if(map.numeroDias && map.numeroDias > 0){
                this.motive = map.numeroDias + (map.numeroDias > 1 ? " días":" día") +" de vacaciones"
            }
            this.requestObject = new HolidaysItem(map); //deserialize(HolidaysItem, map);
            break; 
        }
        case RequestType.Administratives:{
            const configs = configurations.state.configurations || []
            const name = configs.find(configuration => configuration.type === ConfigurationBusinessType.AdministrativeDaysName)?.value 
            if(map.mediosDias){
                this.motive = `Medio ${name && name !== 'Días administrativos' ? name : 'día administrativo'}`
            }else if(map.numeroDias && map.numeroDias > 0){
                const motiveName = map.numeroDias > 1 ? 'días administrativos' : 'día administrativo'
                this.motive = `${map.numeroDias} ${name && name !== 'Días administrativos' ? name : motiveName}` 
            }
            this.requestObject = new AdministrativesItem(map);//deserialize(AdministrativesItem, map);
            break; 
        }
        case RequestType.Permissions:{
            if(map.mediosDias || map.numeroDias){
                this.motive = map.mediosDias ? 'Medio día de permiso' : `${map.numeroDias} día${map.numeroDias > 1 ? 's' : ''} de permiso`
            }else if(map.numeroHoras || map.numeroMinutos){
                this.motive = `${map.numeroHoras ? `${map.numeroHoras} hora${map.numeroHoras > 1 ? 's' : ''}` : ''} ${map.numeroHoras && map.numeroMinutos ? 'y' : ''} ${map.numeroMinutos ? `${map.numeroMinutos} minuto${map.numeroMinutos > 1 ? 's' : ''}` : ''} de permiso`
            }
            
            this.requestObject = new PermissionItem(map);//deserialize(PermissionItem, map);
            break; 
        }
        case RequestType.ExtraHours: {
            let hours = Math.trunc(map.hours)
            let minutes = Math.round((map.hours - hours) * 60)

            let hoursMsg = hours>1 ? " horas" : " hora"
            let minutesMsg = minutes>1 ? " minutos" : " minuto"
            let extra = hours>1 || minutes>1 ? " extras" : " extra"

            this.motive = `${hours ? hours+hoursMsg : ''}${hours && minutes ? ' y ' :''}${minutes ? minutes+minutesMsg : ''}${extra}`
            this.requestObject = new ExtraHoursItem(map)
            break;
        }
        default: {
            this.requestObject = map
            break; 
          // No settings
        }
    }

    //motive: string;
    
    this.dateCreated = map.TS;
    if(map.fechaCreacion){
        this.dateCreated = map.fechaCreacion;
    }

    

    //this.name = undefined;
    //this.lastName = undefined;
    //this.picture = undefined;
    //this.position = undefined;
  }
}

export class statsHoliday {
    diasDeVacacionesPorAno: number;
    diasNormales: number;
    diasNormalesRestantes: number;
    diasProgresivos: number;
    diasProgresivosRestantes: number;
    diasProgresivosVendidos: number;
    diasRestantes: number;
    diasTomados: number;
    diasTomadosProgresivos: number;
    diasTomadosProporcionales: number;
    diasTotales: number;
    diasTrabajados: number;
    mesesTrabajados: number;

    constructor(map: any) {
        this.diasDeVacacionesPorAno = map.diasDeVacacionesPorAno
        this.diasNormales = map.diasNormales
        this.diasNormalesRestantes = map.diasNormalesRestantes
        this.diasProgresivos =  map.diasProgresivos
        this.diasProgresivosRestantes =  map.diasProgresivosRestantes
        this.diasProgresivosVendidos =  map.diasProgresivosVendidos
        this.diasRestantes = map.diasRestantes
        this.diasTomados = map.diasTomados
        this.diasTomadosProgresivos = map.diasTomadosProgresivos
        this.diasTomadosProporcionales = map.diasTomadosProporcionales
        this.diasTotales = map.diasTotales
        this.diasTrabajados = map.diasTrabajados
        this.mesesTrabajados = map.mesesTrabajados
    }
}

export class statsAdministrative {
    diasRestantes: number;
    diasTomados: number;

    constructor(map: any){
        this.diasRestantes = map.diasRestantes
        this.diasTomados = map.diasTomados
    }
}

export class requestStats {
    estadisticasDiasAdministrativos: statsAdministrative;
    estadisticasVacaciones: statsHoliday;
    tipoDiasAdministrativos: {[key: string]: any[]};
    estadisticasDiasAdministrativosDetalle: object

    constructor(map: any){
        this.estadisticasDiasAdministrativos = new statsAdministrative(map.estadisticasDiasAdministrativos)
        this.estadisticasVacaciones = new statsHoliday(map.estadisticasVacaciones)
        this.tipoDiasAdministrativos = map.tipoDiasAdministrativos
        this.estadisticasDiasAdministrativosDetalle = map.estadisticasDiasAdministrativosDetalle
    }
}